import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { memo } from "react";
import Button from "../../components/Button";
import { SkewMainContainer } from "../../components/Container";
import { SectionTitle } from "../../components/Title";
import { SvgDataTrade } from "../../images/index/index";

export const DataTradingPlatformView = memo(() => {
  const { t } = useTranslation();
  return (
    <SkewMainContainer fillClassName="dark-blue">
      <div className="w-full flex flex-col sm:flex-row item-center justify-center sm:-mb-14">
        <div className="sm:w-6/12">
          <SectionTitle id="dataTradingPlatform" className="mb-8 text-white">
            {t("数据交易平台")}
          </SectionTitle>
          <p className="text-md mb-10 leading-6 text-blue">
            {t(
              "星尘数据拥有大量高质量训练数据集，旨在打造一个AI行业的数据交易平台，不论是个人、企业，还是机构，都可在此平台进行咨询、导览、搜索和下载。"
            )}
          </p>
        </div>
        <SvgDataTrade className="mt-8 sm:w-6/12" />
      </div>
      <Button
        className="mt-8"
        onClick={() => window.open("https://store.stardust-ai.com/", "_blank")}
      >
        {t("了解更多")}
      </Button>
    </SkewMainContainer>
  );
});
