import classNames from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { memo } from "react";
import { SkewMainContainer } from "../../components/Container";
import { SectionDescribeText } from "../../components/Section";
import Tabs from "../../components/Tabs";
import { SectionTitle } from "../../components/Title";
import {
  quality_01,
  quality_01_en,
  quality_02,
  quality_02_en,
  quality_03,
  quality_03_en,
  quality_04,
  quality_04_en,
  quality_05,
  quality_05_en,
} from "../../images/index/index";
import { useLanguage, useMediaScreen } from "../../utils/utils";

export const QualityControlView = memo(() => {
  const { t } = useTranslation();
  const { isEnUs } = useLanguage();
  const { isSmScreen } = useMediaScreen();
  const lists = [
    {
      title: t("总览"),
      img: isEnUs ? quality_01_en : quality_01,
      desc: t(
        "* 通过系统匹配、质量控制、实时监控三大机制，确保结构化数据的最高交付标准。"
      ),
    },
    {
      title: t("工作流"),
      img: isEnUs ? quality_02_en : quality_02,
      desc: t(
        "* 用不同类型的工作池，可对项目工作流进行灵活配置，增强项目管理效率"
      ),
    },
    {
      title: t("动态派单"),
      img: isEnUs ? quality_03_en : quality_03,
      desc: t("* 根据不同类型的项目要求，动态匹配最合适的标注团队"),
    },
    {
      title: t("实时质检"),
      img: isEnUs ? quality_04_en : quality_04,
      desc: t(
        "* 可以通过接入外部算法，达到算法自动实时质检，极大提高数据质量。"
      ),
    },
    {
      title: t("多维度筛查"),
      img: isEnUs ? quality_05_en : quality_05,
      desc: t(
        "* 星尘平台的数据可以从多个维度进行质检，保证数据质量。支持按工作池、团队、标注员、错误标签、标注时间、题目流转类型、抽检概率等多维度数据筛查和抽检，满足各类质检场景。"
      ),
    },
  ];
  return (
    <SkewMainContainer
      fillClassName={classNames("bg-[#ECF1F9]")}
      className="pt-8"
    >
      <SectionTitle
        id="qualityControl"
        className={classNames("text-black", "mb-4")}
      >
        {t("质量管控")}
      </SectionTitle>
      <SectionDescribeText>
        {t(
          "数据质量是星尘数据服务的核心，也是星尘创立的初衷。我们设计了一套严格的质量管控体系来保证数据质量，确保数据质量达到您算法的需求。"
        )}
      </SectionDescribeText>
      <Tabs
        contentWrapClassName="shadow-offset-30"
        className="mt-16"
        tabs={lists.map((list) => list.title)}
        touchMove={isSmScreen}
      >
        {lists.map(({ img, desc }, index) => (
          <div key={index} className="bg-white h-[540px] px-8 py-4 text-center">
            <div className="h-full flex flex-col  items-center justify-between ">
              <div className="overflow-x-scroll w-full h-full flex justify-center items-center">
                <div className="overflow-x-scroll w-full ">
                  <img className="min-w-[920px]" src={img} alt="" />
                </div>
              </div>
              <p className="w-7/12 mt-8 mb-8 text-xs text-gray-400">{desc}</p>
            </div>
          </div>
        ))}
      </Tabs>
    </SkewMainContainer>
  );
});
