import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useLayoutEffect, useRef } from "react";
import { SvgOurValue, SvgOurValueEN } from "../../../images/index/index";
import { useLanguage } from "../../../utils/utils";
import "./barrage.css";

const SCROLL_SPEED = 1.6;
const CANVAS_WIDTH = 2800;
type TextItem = {
  x: number;
  y: number;
  text: string;
  color: string;
};

class Barrage {
  private texts: Text[];

  constructor(specs: TextItem[], readonly BarrageContainer: HTMLDivElement) {
    this.texts = [];
    specs.forEach((spec, index) => {
      this.texts.push(new Text(index, spec, this.BarrageContainer));
    });
    requestAnimationFrame(this.animation.bind(this));
  }

  animation() {
    this.texts.forEach((text) => text.update());
    requestAnimationFrame(this.animation.bind(this));
  }
}

class Text {
  private index;
  readonly options;
  readonly el: HTMLDivElement;

  constructor(
    index: number,
    options: TextItem,
    readonly bubbleContainer: HTMLDivElement
  ) {
    this.index = index;
    this.options = options;
    this.el = document.createElement("div");
    this.el.style.color = "#fff";
    this.el.style.backgroundColor = options.color;
    this.el.className = "text-item bg-opacity-30";
    this.el.innerText = options.text;
    bubbleContainer.appendChild(this.el);
  }

  update() {
    const { x, y } = this.options;
    this.options.x = x < -200 ? CANVAS_WIDTH : x - SCROLL_SPEED;
    this.el.style.transform = `translate(${x}px, ${y}px)`;
  }
}

const BarrageView = () => {
  const barrageEl = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { isEnUs } = useLanguage();
  const markingTypes = [
    {
      x: 1134,
      y: 45,
      text: t("数据量大接不住"),
      color: "#CDEFFD",
    },
    {
      x: 1620,
      y: 271,
      text: t("担心数据泄漏"),
      color: "#FAE1F3",
    },
    {
      x: 1761,
      y: 365,
      text: t("标注速度不够快"),
      color: "#DDF9D2",
    },
    {
      x: 1961,
      y: 290,
      text: t("数据正确率不够高"),
      color: "#FBEEC3",
    },
    {
      x: 2499,
      y: 79,
      text: t("变更需求接不了"),
      color: "#CDEFFD",
    },
    {
      x: 2704,
      y: 334,
      text: t("数据标注经验不够多"),
      color: "#FAE1F3",
    },
    {
      text: t("不能快速研发新功能"),
      color: "#DDF9D2",
      x: 2271,
      y: 356,
    },
    {
      text: t("交付数据总是晚"),
      color: "#FBEEC3",
      x: 795,
      y: 226,
    },
    {
      text: t("价格贵成本hold不住"),
      color: "#CDEFFD",
      x: 276,
      y: 256,
    },
    {
      text: t("担心项目服务跟不上"),
      color: "#FAE1F3",
      x: 920,
      y: 365,
    },
    {
      x: 444,
      y: 193,
      text: t("数据验收麻烦"),
      color: "#DDF9D2",
    },
    {
      x: 2545,
      y: 387,
      text: t("数据验收效率低"),
      color: "#FBEEC3",
    },
    {
      x: 1303,
      y: 193,
      text: t("专业度低沟通不畅"),
      color: "#CDEFFD",
    },
    {
      x: 907,
      y: 88,
      text: t("响应不及时"),
      color: "#FAE1F3",
    },
    {
      x: 633,
      y: 320,
      text: t("标注工具不够多"),
      color: "#CDEFFD",
    },
    {
      x: 129,
      y: 357,
      text: t("交互难用、界面不易懂"),
      color: "#CDEFFD",
    },
    {
      x: 1140,
      y: 302,
      text: t("标注智能化程度不够高"),
      color: "#CDEFFD",
    },
    {
      x: 2529,
      y: 293,
      text: t("不能快速定位错标的地方"),
      color: "#CDEFFD",
    },
    {
      x: 2135,
      y: 198,
      text: t("团队成员不易管理"),
      color: "#CDEFFD",
    },
    {
      x: 2076,
      y: 102,
      text: t("不能量化工作效率"),
      color: "#CDEFFD",
    },
    {
      x: 2654,
      y: 182,
      text: t("项目进度不知情"),
      color: "#CDEFFD",
    },
    {
      x: 2983,
      y: 60,
      text: t("数据准确率不高"),
      color: "#CDEFFD",
    },
    {
      x: 1519,
      y: 118,
      text: t("担心数据被黑客攻击"),
      color: "#CDEFFD",
    },
    {
      x: 1071,
      y: 233,
      text: t("工作量统计太麻烦"),
      color: "#CDEFFD",
    },
    {
      x: 1773,
      y: 148,
      text: t("数据质量难保证"),
      color: "#CDEFFD",
    },
    {
      x: 2098,
      y: 385,
      text: t("工作流程混乱"),
      color: "#CDEFFD",
    },
    {
      x: 2423,
      y: 244,
      text: t("供应商资源不足"),
      color: "#CDEFFD",
    },
  ];
  useLayoutEffect(() => {
    if (barrageEl.current) {
      new Barrage(markingTypes, barrageEl.current);
    }
  }, [barrageEl.current]);
  return (
    <div className="barrage-wrap min-w-[920px]">
      <div ref={barrageEl} className="overflow-hidden w-full" />
      <div className="h-[452px] relative z-10 barrage-mask overflow-hidden">
        {isEnUs ? (
          <SvgOurValueEN
            className="h-full"
            style={{ position: "relative", left: "calc( 100vw/2 - 1360px)" }}
          />
        ) : (
          <SvgOurValue
            className="h-full"
            style={{ position: "relative", left: "calc( 100vw/2 - 1360px)" }}
          />
        )}
      </div>
    </div>
  );
};

export default BarrageView;
