import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { memo } from "react";
import { SkewContainer } from "../../../components/Container";
import { SectionTitle } from "../../../components/Title";
import BarrageView from "./Barrage";

const ValueView = memo(() => {
  const { t } = useTranslation();
  return (
    <SkewContainer fillClassName="bg-white">
      <div className="flex flex-col items-center">
        <h3 className="text-center text-color-primary font-bold text-lg mt-8">
          {t("星尘的价值")}
        </h3>
        <SectionTitle className="text-center" id="stardustValue">
          {t("一站式AI数据解决方案")}
        </SectionTitle>
        <p className="sm:w-[600px] text-color-blue-gary text-center mt-6 px-6">
          {t(
            "Stardust致力于不断创新，将人与AI结合起来，通过数据运营中心和AI智能标注工具，将我们的工程师与行业资深项目人员配合，不断传递给客户更高质量的数据，为AI未来的发展提供动力。"
          )}
        </p>
      </div>
      <div className="w-full">
        <BarrageView />
      </div>
    </SkewContainer>
  );
});

export default ValueView;
