import { graphql, navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { FC, ReactNode } from "react";
import { IndexPageQuery } from "../../graphql-types";
import { ContactButton } from "../components/Button";
import { MainContainer, PageContainer } from "../components/Container";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Metadata from "../components/Metadata";
import SearchInput from "../components/SearchInput";
import SkewGradientBg from "../components/SkewGradientBg";
import { autopilot, home_video_cover } from "../images";
import { usePageMetadata } from "../metadata/page";
import { BrandLogoView } from "../Views/indexViews/BrandLogoView";
import CustomerView from "../Views/indexViews/CustomerView";
import DataSecurityView from "../Views/indexViews/DataSecurityView";
import { DataTradingPlatformView } from "../Views/indexViews/DataTradingPlatformView";
import { QualityControlView } from "../Views/indexViews/QualityControlView";
import { ServiceAdvantageView } from "../Views/indexViews/ServiceAdvantageView";
import ServiceProcessView from "../Views/indexViews/ServiceProcessView";
import { ServiceScenarioView } from "../Views/indexViews/ServiceScenarioView";
import ValueView from "../Views/indexViews/ValueView/ValueView";
import LearnMoreView from "../Views/LearnMoreView";

export type TitleWithLogoAndDesc = {
  logo: ReactNode;
  title: string;
  desc: string;
};

const IndexPage: FC<{ data: IndexPageQuery }> = ({ data }) => {
  const { t } = useTranslation();
  const { index } = usePageMetadata();
  return (
    <PageContainer>
      <Header />
      <Metadata {...index} />
      <SkewGradientBg
        id="gradient-canvas"
        className="h-[780px]"
        gradientHeight={780}
      />
      <MainContainer className="pb-6 pt-24">
        {/* 首页cover */}
        <div className="flex flex-wrap relative">
          {/* titles */}
          <div className="w-full sm:w-[400px]">
            <h2 className="gradient-text text-4xl sm:text-5xl leading-normal">
              {t("领先的人工智能")}
              <br />
              {t("数据服务平台")}
            </h2>
            <p className="mt-6 text-xl text-white">
              {t("企业级AI训练数据 SaaS 平台")}
            </p>
          </div>
          {/* video cover */}
          <div className="relative sm:ml-24 mt-8 sm:mt-0">
            <div className="sm:absolute z-30 rounded-md">
              {/* pc */}
              <video
                className="max-w-none rounded-md w-[600px] z-10 hidden sm:block"
                autoPlay
                loop
                muted
                playsInline
              >
                <source src={autopilot} type="video/mp4" />
              </video>
              {/* mobile */}
              <video
                poster={home_video_cover}
                className="max-w-none w-full rounded-md sm:hidden"
                autoPlay={false}
                loop
                muted
                playsInline
                controls
              >
                <source src={autopilot} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
        <div className="w-full sm:w-[400px] mt-12">
          {/* 搜索框要再小一点，多一些留白 */}
          <SearchInput />
          <div className="flex justify-between mt-7">
            <ContactButton
              onClick={() => navigate("/product")}
              className="bg-transparent transition border-2 w-60 mr-4"
            >
              {t("产品优势")}
            </ContactButton>
            <ContactButton
              className="bg-transparent transition border-2 w-60"
              onClick={() => navigate("/contact")}
            >
              {t("联系销售")}
            </ContactButton>
          </div>
        </div>
      </MainContainer>
      <BrandLogoView />
      <ServiceScenarioView lists={data.allContentfulScenario.nodes} />
      <ServiceProcessView />
      <ServiceAdvantageView
        technicalSolutions={data.allContentfulSolutions.nodes ?? []}
      />
      <QualityControlView />
      <DataSecurityView />
      <DataTradingPlatformView />
      <ValueView />
      <CustomerView lists={data.allContentfulCustomers.edges} />
      <LearnMoreView
        leftText={t("产品优势")}
        leftUrl={"/product"}
        rightText={t("联系销售")}
        rightUrl={"/contact"}
      />
      <Footer />
    </PageContainer>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPage($language: String!) {
    ...AllLangauages
    allContentfulScenario(filter: { node_locale: { eq: $language } }) {
      nodes {
        id
        slug
        title
        preview_logo {
          ...ContentfulImage200x200
        }
        preview
        order
      }
    }
    allContentfulCustomers(filter: { node_locale: { eq: $language } }) {
      edges {
        node {
          id
          position
          name
          words
          companyName
          avatar {
            ...ContentfulImage200x200
          }
        }
      }
    }
    allContentfulSolutions(filter: { node_locale: { eq: $language } }) {
      nodes {
        id
        slug
        icon {
          ...ContentfulImage200x200
        }
        coverImage {
          ...ContentfulImage200x200
        }
        name
        description {
          description
        }
        technologyType {
          title
          slug
        }
        serviceScenarios {
          title
        }
      }
    }
  }
`;
