import { Carousel } from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { memo, useRef } from "react";
import { IndexPageQuery } from "../../../graphql-types";
import { ShowMoreButton } from "../../components/Button";
import { SkewMainContainer } from "../../components/Container";
import ContentfulImage from "../../components/ContentfulImage";
import { SectionTitle } from "../../components/Title";
import {
  a10,
  a11,
  a12,
  a13,
  a14,
  a15,
  a16,
  a17,
  a18,
  a19,
  a20,
  a21,
  a22,
  a23,
  a24,
  a25,
  a26,
  a27,
  a28,
  a29,
  a30,
  aO1,
  aO2,
  aO3,
  aO4,
  aO5,
  aO6,
  aO7,
  aO8,
  aO9,
} from "../../images/index/index";

type CustomerItem = IndexPageQuery["allContentfulCustomers"]["edges"][number];

const CustomerView = memo((props: { lists: CustomerItem[] }) => {
  const { t } = useTranslation();
  const ref = useRef<CarouselRef>(null);

  return (
    <SkewMainContainer fillClassName="light-blue-bg">
      <SectionTitle id="customer" className="mb-8">
        {t("客户")}
      </SectionTitle>
      <Carousel
        className="rounded-lg shadow-offset-30 overflow-hidden index-customer"
        autoplay
        ref={ref}
        dots={{ className: "index-customer-dot" }}
      >
        {props.lists.map((list, idx) => {
          return (
            <div key={`${list}${idx}`} className="bg-white">
              {/* <div className="p-10 grid grid-cols-1 sm:grid-cols-8 sm:gap-16 gap-8"> */}
              <div className="p-10 flex sm:flex-row flex-col justify-items-start items-center w-full">
                {/* <div className="flex sm:col-span-2 items-center mt-4"> */}
                <div className="flex items-center justify-items-center">
                  <ContentfulImage
                    className="w-24 h-24 rounded-full flex-none mr-8 sm:mb-0 mb-4"
                    image={list.node.avatar!}
                  />
                </div>
                <div>
                  <div>
                    <h5 className="text-sm text-gray-400 mb-4">
                      {list.node.name}
                    </h5>
                    {/* <p className="text-color-blue-gary text-xs">
                      {`${list.node.companyName}、${list.node.position}`}
                    </p> */}
                  </div>
                  <p className="w-auto sm:text-lg text-base leading-7 sm:col-span-6">
                    "{list.node.words}"
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </Carousel>
      <div className="grid grid-cols-3 sm:grid-cols-6 gap-8 mt-12 justify-between">
        {[
          aO1,
          aO2,
          aO3,
          aO4,
          aO5,
          aO6,
          aO7,
          aO8,
          aO9,
          a10,
          a11,
          a12,
          a13,
          a14,
          a15,
          a16,
          a17,
          a18,
          a19,
          a20,
          a21,
          a22,
          a23,
          a24,
          a25,
          a26,
          a27,
          a28,
          a29,
          a30,
        ].map((url, index) => {
          return (
            <div
              key={`url_${index}`}
              className="h-24 flex justify-center items-center"
            >
              <img
                className="w-20 filter grayscale transition hover:grayscale-0 hover:scale-110"
                src={url}
                alt=""
              />
            </div>
          );
        })}
      </div>
      <ShowMoreButton
        arrowDirection="right"
        onClick={() => navigate("/customer-case")}
      >
        {t("查看客户案例")}
      </ShowMoreButton>
    </SkewMainContainer>
  );
});
export default CustomerView;
