import classNames from "classnames";
import { graphql } from "gatsby";
import * as React from "react";
import { PNGhomeArrows } from "../images/index/index";
import { ListProps } from "./Section";

type LeftContentRightButtonProps = {
  title: string;
  content: string;
  buttonText: string;
  hideButtonArrow?: boolean;
  className?: string;
  onClick?: () => void;
};

export type LeftRightLayoutProps = {
  lists: ListProps[];
  logo: string;
  isDark?: boolean;
};

export const LeftContentRightButton = (props: LeftContentRightButtonProps) => {
  return (
    <div
      className={classNames(
        "grid grid-cols-1 sm:grid-cols-7 gap-8 custom-shadow px-6 sm:px-12 py-10 rounded-xl items-center justify-center bg-white relative overflow-hidden",
        props.className
      )}
    >
      {/* 插图 */}
      <div className="hidden sm:block absolute h-48 z-0 top-5 left-8">
        <img className="h-full" src={PNGhomeArrows} alt="" />
      </div>

      {/* 文字 */}
      <div className="sm:col-span-4 sm:pl-40">
        <h4 className="text-xl text-center sm:text-left mb-4 text-black">
          {props.title}
        </h4>
        <p className="text-sm leading-6 disclaimer-color">{props.content}</p>
      </div>

      {/* 按钮 */}
      <div className="sm:col-span-2 flex justify-center">
        <div
          className="rounded-md bright-blue w-60 h-10 text-center text-white leading-10 cursor-pointer"
          onClick={props.onClick}
        >
          {props.buttonText}
        </div>
      </div>
    </div>
  );
};

export const SectionLeftRightLayout = ({
  lists,
  logo,
  className,
  isDark,
}: LeftRightLayoutProps & { className?: string }) => {
  return (
    <div
      className={classNames("grid grid-cols-1 sm:grid-cols-2 gap-8", className)}
    >
      <div className="text-white mr-8">
        {lists.map((list) => {
          return (
            <div
              key={list.title}
              className="flex mb-12"
              style={{
                backgroundImage: "url(../images/index/customStageBg.png)",
              }}
            >
              {/* 替换成logo的svg*/}
              {list.cardLogo ? (
                list.cardLogo
              ) : (
                <div className="w-12 h-12 placeholder-bg flex-none" />
              )}
              <div className="ml-4">
                <h4
                  className={classNames(
                    "text-ls font-semibold mb-2",
                    isDark ? "text-white" : "text-black"
                  )}
                >
                  {list.title}
                </h4>
                <p className="text-xs text-blue">{list.content}</p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="placeholder-bg rounded-md h-72" />
    </div>
  );
};

export const query = graphql`
  fragment AllLangauages on Query {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
