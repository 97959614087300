import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { useState } from "react";
import { SkewMainContainer } from "../../components/Container";
import { LeftContentRightButton } from "../../components/Layout";
import { SectionList, ServicesScenarioItem } from "../../components/Section";
import { SectionTitle } from "../../components/Title";
import { DownArrow } from "../../images";

export const ServiceScenarioView = React.memo(
  (props: { lists: ServicesScenarioItem[] }) => {
    const { t } = useTranslation();
    const [isShowMore, setIsShowMore] = useState(false);
    const sortList = props.lists.sort(
      (a, b) => a?.order ?? 999 - b?.order ?? 999
    );
    const serviceScenarioLinkInfo = {
      title: t("需要为企业定制特殊场景?"),
      content: t(
        "我们能够为您的企业定制专属标注服务，并提供云端部署、私有化部署，确保数据安全。"
      ),
      buttonText: t("了解数据定制与数据集"),
    };
    return (
      <SkewMainContainer hideTop fillClassName="custom-gray-bg">
        <SectionTitle id="serviceScenario">{t("服务场景")}</SectionTitle>
        <SectionList
          className="pt-10"
          lists={isShowMore ? sortList : sortList.slice(0, 10)}
          linkPrefix={"scenario"}
        />
        {!isShowMore && sortList.length > 10 && (
          <div className="flex justify-center mt-14">
            <div
              className="primary-text flex bg-white rounded-full h-10 w-48 items-center justify-center cursor-pointer"
              onClick={() => setIsShowMore(true)}
            >
              <span className="mr-3">{t("显示更多场景")}</span>
              <DownArrow />
            </div>
          </div>
        )}

        <LeftContentRightButton
          className="mt-14"
          hideButtonArrow
          onClick={() => navigate("/contact?group=serviceScenarioViews")}
          {...serviceScenarioLinkInfo}
        />
      </SkewMainContainer>
    );
  }
);
