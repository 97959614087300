import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { memo } from "react";
import { SkewMainContainer } from "../../components/Container";
import { SectionTitle } from "../../components/Title";
import ServiceFlow from "../../images/index/serviceProcess/service_flow.png";
import ServiceFlowEN from "../../images/index/serviceProcess/service_flow_en.png";
import { useLanguage } from "../../utils/utils";

const ServiceProcessView = memo(() => {
  const { t } = useTranslation();
  const { isEnUs } = useLanguage();
  return (
    <SkewMainContainer fillClassName="dark-blue">
      <SectionTitle id="serviceProcess" className="text-white">
        {t("服务流程")}
      </SectionTitle>
      <p className="text-blue mt-6">
        {t(
          "星尘数据的使命是加速人工智能的发展。我们通过提供一个以数据为中心的端到端解决方案来管理整个机器学习生命周期。"
        )}
      </p>
      <div className="mt-16">
        <div className="overflow-x-scroll w-full">
          {isEnUs ? (
            <img className="min-w-[920px]" src={ServiceFlowEN} alt="" />
          ) : (
            <img className="min-w-[920px]" src={ServiceFlow} alt="" />
          )}
        </div>
      </div>
    </SkewMainContainer>
  );
});

export default ServiceProcessView;
