import classNames from "classnames";
import { navigate } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react";
import { memo } from "react";
import Button from "../../components/Button";
import { SkewMainContainer } from "../../components/Container";
import { SectionTitle } from "../../components/Title";
import { SvgDataSecurity } from "../../images/index/index";

const DataSecurityView = memo(() => {
  const { t } = useTranslation();
  return (
    <SkewMainContainer fillClassName="bg-white">
      <div className="w-full flex flex-col sm:flex-row item-center justify-center sm:-my-16">
        <SvgDataSecurity className="mt-8 sm:w-6/12" />
        <div className="sm:w-6/12 flex flex-col justify-center sm:ml-8 mb-8">
          <SectionTitle
            id="dataSecurity"
            className={classNames("text-black", "mb-5")}
          >
            {t("数据安全")}
          </SectionTitle>
          <p className="text-md mb-12 leading-6">
            <li className="mb-1">
              {t(
                "星尘数据安全解决方案让您的数据安全无后顾之忧，不论在离线环境中还是端到端的数据环境均有完整的解决方案"
              )}
            </li>
            <li className="mb-1">
              {t(
                "不论在离线环境中还是端到端的数据环境均有完整的解决方案，星尘数据安全解决方案让您的数据安全无后顾之忧"
              )}
            </li>
            <li className="mb-1">
              {t(
                "面临黑客攻击、数据泄露、数据被解密……怎么办？星尘数据安全解决方案为您的数据保驾护航，解决您的后顾之忧"
              )}
            </li>
          </p>
          <Button
            onClick={() => navigate("/contact?group=dataTradingPlatform")}
            className="mt-8 sm:-mt-4"
          >
            {t("了解更多")}
          </Button>
        </div>
      </div>
    </SkewMainContainer>
  );
});
export default DataSecurityView;
