import * as React from "react";
import { memo } from "react";
import { SkewContainer } from "../../components/Container";
import {
  a12,
  a13,
  a18,
  a22,
  a28,
  aO1,
  aO2,
  aO3,
  aO4,
  aO5,
  aO6,
} from "../../images/index/index";

export const BrandLogoView = memo(() => {
  return (
    <SkewContainer
      fillClassName="bg-white z-[1]"
      hasPadding={false}
      hideBottom
      className="sm:pb-0"
    >
      <div className="max-w-[1120px] mx-auto py-[20px] relative z-[10]">
        <div className="w-full h-24 overflow-x-hidden relative brand-logo-container">
          <div className="flex brand-logo-wrap flex-nowrap items-center pl-16">
            {[
              aO1,
              aO2,
              aO3,
              aO4,
              a12,
              aO6,
              a18,
              a13,
              a28,
              aO5,
              a22,
              a12,
              aO1,
              aO2,
              aO3,
              aO4,
              a12,
              aO6,
              a18,
              a13,
              a28,
              aO5,
              a22,
              a12,
            ].map((url, index) => {
              return (
                <img className="mr-24 w-32" key={index} src={url} alt="" />
              );
            })}
          </div>
        </div>
      </div>
    </SkewContainer>
  );
});
